const state = () => ({
  recruiterInterviews: [],
  recruiterInterview: {},
  candidateEvaluation: {},
  candidatesEvaluated: [],
  averageAssessmentCandidate: [],
})

const getters = {
  recruiterInterviews: (state) => state.recruiterInterviews,
  recruiterInterview: (state) => state.recruiterInterview,
  candidateEvaluation: (state) => state.candidateEvaluation,
  candidatesEvaluated: (state) => state.candidatesEvaluated,
  averageAssessmentCandidate: (state) => state.averageAssessmentCandidate,
}

const actions = {
  getRecruiterInterviews({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get('/selective-processes/interview-recruiter', { headers })
      .then((response) => {
        commit('setRecruiterInterviews', response.data)
        return Promise.resolve(response.data)
      })
  },

  postCriteriaRecruiterInterview(context, { payload }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .post('/selective-processes/interview-recruiter', payload, { headers })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },

  deleteCriteriaRecruiterInterview(context, { idStage }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .delete(`/selective-processes/interview-recruiter/${idStage}`, {
        headers,
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },
  patchRecruiterInterview(context, { idStage, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .patch(`/selective-processes/interview-recruiter/${idStage}`, payload, {
        headers,
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },

  getRecruiterInterview({ commit }, { idStage }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(`/selective-processes/interview-recruiter/${idStage}`, { headers })
      .then((response) => {
        commit('setRecruiterInterview', response.data)
        return Promise.resolve(response.data)
      })
  },

  postCandidateEvaluation(context, { idStage, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .post(
        `/selective-processes/interview-recruiter/${idStage}/candidate-evaluation`,
        payload,
        { headers }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },

  getCandidateEvaluation({ commit }, { idStage, idUser }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(
        `/selective-processes/interview-recruiter/${idStage}/candidate-evaluation/${idUser}`,
        { headers }
      )
      .then((response) => {
        commit('setCandidateEvaluation', response.data)
        return Promise.resolve(response.data)
      })
  },
  patchCandidateEvaluation(context, { idStage, idUser, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .patch(
        `/selective-processes/interview-recruiter/${idStage}/candidate-evaluation/${idUser}`,
        payload,
        { headers }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },

  getCandidatesEvaluated({ commit }, { idStage }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(
        `/selective-processes/interview-recruiter/${idStage}/candidate-evaluation`,
        {
          headers,
        }
      )
      .then((response) => {
        commit('setCandidatesEvaluated', response.data)
        return Promise.resolve(response.data)
      })
  },

  deleteEvaluatedCandidate(context, { idStage, idUser }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .delete(
        `/selective-processes/interview-recruiter/${idStage}/candidate-evaluation/${idUser}`,
        { headers }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },

  getCandidateAnnotations(context, { stageId, candidateId }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(`/stages/${stageId}/candidate-stages/${candidateId}/edit`, {
        headers,
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },

  patchCandidateAnnotations(context, { stageId, candidateId, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .patch(
        `/stages/${stageId}/candidate-stages/${candidateId}/edit`,
        payload,
        { headers }
      )
      .then((response) => {
        return Promise.resolve(response.data)
      })
  },
}

const mutations = {
  setRecruiterInterviews(state, recruiterInterviews) {
    state.recruiterInterviews = recruiterInterviews
  },
  setRecruiterInterview(state, recruiterInterview) {
    state.recruiterInterview = recruiterInterview
  },
  setCandidateEvaluation(state, candidateEvaluation) {
    state.candidateEvaluation = candidateEvaluation
  },
  setCandidatesEvaluated(state, candidatesEvaluated) {
    state.candidatesEvaluated = candidatesEvaluated

    const scores = {}

    candidatesEvaluated.forEach((candidate) => {
      if (!scores[candidate.user]) {
        scores[candidate.user] = { sum: 0, count: 0 }
      }
      scores[candidate.user].sum += candidate.score
      scores[candidate.user].count += 1
    })

    state.averageAssessmentCandidate = Object.keys(scores).map((user) => {
      const average = scores[user].sum / scores[user].count
      return {
        candidate: user,
        averageScore: Number.isInteger(average)
          ? average.toFixed(0)
          : average.toFixed(1),
      }
    })
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
