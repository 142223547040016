const state = () => ({
  selectiveProcesses: [],
  candidatesOfSelectiveProcess: [],
  add: false,
  manage: true,
  currentSelectiveProcess:
    localStorage.getItem('currentSelectiveProcess') || null,
  criteria: [],
  candidatesOfStage: [],
  candidatesOfSelectiveProcessTotalItems: 0,
  selectiveProcess: [],
  totalClosedSelectiveProcesses: 0,
  averageProcessDuration: 0,
  totalOpenSelectiveProcesses: 0,
  candidatesOfStageNoPaginated: [],
})

const getters = {
  selectiveProcesses: (state) => state.selectiveProcesses.results,
  selectiveProcess: (state) => state.selectiveProcess,
  totalSelectiveProcesses: (state) => state.selectiveProcesses.total_items,
  totalPagesSelectiveProcesses: (state) => state.selectiveProcesses.total_pages,
  totalOpenSelectiveProcesses: (state) => state.totalOpenSelectiveProcesses,
  totalClosedSelectiveProcesses: (state) => state.totalClosedSelectiveProcesses,
  manageSelectiveProcesses: (state) => state.selectiveProcesses.manage,
  addSelectiveProcesses: (state) => state.selectiveProcesses.add,
  currentSelectiveProcess: (state) => state.currentSelectiveProcess,
  candidatesOfSelectiveProcess: (state) => state.candidatesOfSelectiveProcess,
  criteria: (state) => state.criteria,
  candidatesOfStage: (state) => state.candidatesOfStage,
  candidatesOfSelectiveProcessTotalItems: (state) =>
    state.candidatesOfSelectiveProcessTotalItems,
  averageProcessDuration: (state) => state.averageProcessDuration,
  candidatesOfStageNoPaginated: (state) => state.candidatesOfStageNoPaginated,
}

const actions = {
  deleteStage(context, stageId) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/stages/${stageId}`
    return this._vm.$axios.delete(url, { headers })
  },

  editStage(context, { stepId, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/stages/${stepId}`

    return this._vm.$axios.put(url, payload, { headers })
  },

  addStage(context, { selectiveProcessId, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/selective-processes/${selectiveProcessId}/stages`

    return this._vm.$axios.post(url, payload, { headers })
  },

  getStages(context, selectiveProcessId) {
    const headers = {
      Authorization: this._vm.token,
    }
    const url = `/selective-processes/${selectiveProcessId}/stages`

    return this._vm.$axios.get(url, { headers })
  },

  async preSelectCandidates(context, { selectiveProcessId, candidateIds }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const payload = {
      candidates: candidateIds,
    }
    const url = `/selective-processes/${selectiveProcessId}/pre-select`

    try {
      return await this._vm.$axios.post(url, payload, { headers })
    } catch (error) {
      console.error('Erro ao pré-selecionar candidatos: ', error)
      throw error
    }
  },

  addCandidatesToStage(context, { stepId, candidateIds }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const payload = {
      candidates: candidateIds,
    }
    const url = `/stages/${stepId}/register-candidates`

    return this._vm.$axios.post(url, payload, { headers })
  },

  getCandidatesBySelectiveProcess({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }
    const { id, limit = 10, page = 1 } = params
    const url = `/selective-processes/${id}/candidate-stages`

    return this._vm.$axios
      .get(url, { headers, params: { limit, page } })
      .then(({ data }) => {
        commit('setCandidatesOfSelectiveProcess', data)
        return Promise.resolve(data)
      })
  },

  getCandidatesByStage({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    const { id, limit = 10, page = 1 } = params

    const url = `/stages/${id}/candidate-stages`

    return this._vm.$axios
      .get(url, { headers, params: { limit, page } })
      .then(({ data }) => {
        commit('setCandidatesOfStage', data)
        return Promise.resolve(data)
      })
  },

  getCandidatesByStageNoPaginated({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    const { id } = params

    const url = `/stages/${id}/candidate-stages?no_paginate=true`

    return this._vm.$axios.get(url, { headers }).then((response) => {
      commit('setCandidatesOfStageNoPaginated', response.data)
      return Promise.resolve(response.data)
    })
  },

  editSelectiveProcess({ commit, dispatch }, payload) {
    const headers = {
      Authorization: this._vm.token,
    }
    if (payload.image) {
      headers['Content-Type'] = 'multipart/form-data'
    }
    const id = payload.id
    delete payload['id']
    return this._vm.$axios
      .patch(`/selective-processes/${id}`, payload, { headers })
      .then((response) => {
        dispatch('getSelectiveProcesses', response.data)
        dispatch('getSelectiveProcess', response.data.id)
        commit('setCurrentSelectiveProcess', response.data.id)
        return Promise.resolve(response.data)
      })
  },

  createSelectiveProcess(context, { payload, headers = {} }) {
    const _headers = {
      Authorization: this._vm.token,
      ...headers,
    }
    return this._vm.$axios
      .post('/selective-processes/', payload, { headers: _headers })
      .then(({ data }) => data)
  },

  getSelectiveProcesses({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get('/selective-processes/', { params, headers })
      .then((response) => {
        commit('setSelectiveProcesses', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSelectiveProcess({ commit }, id) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get(`/selective-processes/${id}`, { headers })
      .then((response) => {
        commit('setSelectiveProcess', response.data)
        return Promise.resolve(response.data)
      })
  },

  getCandidateResume(context, { selectiveProcessId, candidateId }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get(
        `/selective-processes/${selectiveProcessId}/candidate-resume/${candidateId}`,
        { headers }
      )
      .then(({ data }) => data)
  },

  getCandidateSubmission(context, { stageId, candidateId }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get(`/stages/${stageId}/candidate-submission/${candidateId}`, {
        headers,
      })
      .then(({ data }) => data)
  },

  getCandidateQuestionaryAnswer(context, { candidateStageId }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get(
        `/candidate-selective-processes/${candidateStageId}/evaluate-technical-test`,
        {
          headers,
        }
      )
      .then(({ data }) => data)
  },

  putCandidateQuestionaryAnswer(context, { candidateStageId, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .put(
        `/candidate-selective-processes/${candidateStageId}/evaluate-technical-test`,
        payload,
        {
          headers,
        }
      )
      .then(({ data }) => data)
  },

  getCandidateInterview(context, { stageId, candidateId }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get(`/stages/${stageId}/candidate-interview/${candidateId}`, {
        headers,
      })
      .then(({ data }) => {
        return Promise.resolve(data)
      })
  },

  postCandidateInterview(context, { stageId, candidateId, payload }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .post(`/stages/${stageId}/candidate-interview/${candidateId}/`, payload, {
        headers,
      })
      .then(({ data }) => data)
  },

  approveCandidate({ dispatch }, { stageId, candidateId }) {
    return dispatch('approveCandidates', {
      stageId,
      candidateIds: [candidateId],
    })
  },

  approveCandidates(context, { stageId, candidateIds }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const payload = {
      candidates: candidateIds,
    }
    const url = `/stages/${stageId}/approve-candidates`
    return this._vm.$axios.post(url, payload, { headers })
  },

  hireCandidate(context, { stageId, candidateId }) {
    const headers = {
      Authorization: this._vm.token,
    }
    const payload = {
      candidates: [candidateId],
    }
    const url = `/stages/${stageId}/hire-candidates`
    return this._vm.$axios.post(url, payload, { headers })
  },

  getCriteria({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }

    return this._vm.$axios
      .get('/evaluation-criteria/', { headers })
      .then((response) => {
        commit('setCriteria', response.data)
        return Promise.resolve(response.data)
      })
  },

  getSelectiveProcessesNoPaginated({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/selective-processes/?no_paginate=true', { headers })
      .then((response) => {
        commit('setSelectiveProcessesNoPaginated', response.data)
        return Promise.resolve(response.data)
      })
  },

  getAverageProcessDuration({ commit }) {
    const headers = {
      Authorization: this._vm.token,
    }
    return this._vm.$axios
      .get('/selective-processes/average-duration', { headers })
      .then((response) => {
        commit('setAverageProcessDuration', response.data.average_duration)
        return Promise.resolve(response.data)
      })
  },

  getSearchSelectiveProcesses({ commit }, params = {}) {
    const headers = {
      Authorization: this._vm.token,
    }

    if (params.order_by == '') delete params.order_by

    params = {
      ...params,
      order: 'asc',
    }

    return this._vm.$axios
      .get('/selective-processes/', { params, headers })
      .then((response) => {
        commit('setSelectiveProcesses', response.data)
        return Promise.resolve(response.data)
      })
  },
}

const mutations = {
  setSelectiveProcesses(state, selective_process) {
    state.selectiveProcesses = selective_process
  },
  setSelectiveProcess(state, selective_process) {
    state.selectiveProcess = selective_process
  },
  setAddSelectiveProcess(state, status) {
    state.add = status
    state.manage = !status
  },
  setManageSelectiveProcess(state, status) {
    state.manage = status
    state.add = !status
  },
  setCurrentSelectiveProcess(state, id) {
    if (id == null) {
      localStorage.removeItem('currentSelectiveProcess')
      state.currentSelectiveProcess = null
    } else {
      state.currentSelectiveProcess = id
      localStorage.setItem('currentSelectiveProcess', id)
    }
  },
  setAvailableSelectiveProcesses(selective_processes) {
    state.availableSelectiveProcesses = selective_processes
  },
  setCriteria(state, criteria) {
    state.criteria = criteria
  },
  setCandidatesOfSelectiveProcess(state, candidates) {
    state.candidatesOfSelectiveProcess = candidates.results
    state.candidatesOfSelectiveProcessTotalItems = candidates.count
  },
  setCandidatesOfStage(state, candidates) {
    state.candidatesOfStage = candidates.results
  },
  setSelectiveProcessesNoPaginated(state, selective_process) {
    state.totalClosedSelectiveProcesses = selective_process.filter(
      (selective_process) =>
        selective_process.status === 'Finalizado com Contratação' ||
        selective_process.status === 'Finalizado sem Contratação'
    ).length
    state.totalOpenSelectiveProcesses = selective_process.filter(
      (selective_process) => selective_process.status === 'Em Execução'
    ).length
  },
  setAverageProcessDuration(state, averageProcessDuration) {
    state.averageProcessDuration = averageProcessDuration
  },
  setCandidatesOfStageNoPaginated(state, candidates) {
    state.candidatesOfStageNoPaginated = candidates
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
